@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Teko:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.numberInput {
  width: 100% !important;
  border: 1px solid #e5e7eb !important;
  background: #fafafa !important;
}
.invalid-number-message {
  padding: 0 2px !important;
  top: -10px !important;
}
